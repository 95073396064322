import React from "react";
import { widgetColorStyle } from "../utils/utils";
import { StorageService } from "../service/token/storage.service";

export function HidenIcon({ onOpen,
   isChatOpen,
    isFirstOpen, 
    widgetOptions,
      messagePhoto, 
      telegramBotLink, 
      viberBotLink, 
      setIsWelcomScreenOpen,
      sendSocketJWAuth}) {

  const onClickHidenButton = () => {
    onOpen();
    if (!viberBotLink && !telegramBotLink) {
      if (StorageService.getCustomerIdTocken() === null) {
        setTimeout(() => {
          sendSocketJWAuth()
        },1000)
      }
      setIsWelcomScreenOpen(false)
    }
  };

  const { color } = widgetOptions;

  return (
    <div
      onClick={() => onClickHidenButton()}
      className={`hiden-icon ${
        isChatOpen ? "hiden-icon--open" : isFirstOpen ? "hiden-icon--close" : ""
      }`}
    >
      <div className="hiden-icon__wrapper-backdrop">
      <div className="hiden-icon__wrapper" style={{
           background: widgetColorStyle(color).gradient,
          }}>
            
      <img className="hiden-icon__img_wrapper" src={messagePhoto} alt="" />
     </div>
     </div>
    </div>
  );
}
