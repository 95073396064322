const widgetState = {
  color: "#438DF6",
  fontColor: "#ffffff",
  multilanguageText: {
    uk: {
      widgetHeadStart: "Привіт",
      widgetTextStart: "Готова система турботи для ваших клієнтів за 30 хвилин",
      dialogButtonText: "Написати нам",
      widgetHead: "У вас є запитання?",
      widgetText: "Звичайний час відповіді 5 хвилин",
      welcomMessage: "Привіт.\nЯке в тебе питання?",
      widgetAvatarText: "Богдан",
      tabMessageText: "У вас нове повідомлення!",
      widgetBeforeOpenText: "Привіт) \nВам потрібна допомога?",
      messagesBlockHead: "Зв'язок через месенджери",
      messagesBlockDescription:
        "Ви можете зв'язатися з нами через зручний месенджер або відправити повідомлення в чаті праворуч тут!",
      showWidget: true,
    },
    ru: {
      widgetHeadStart: "Привет",
      widgetTextStart: "Готовая система заботы для ваших клиентов за 30 минут",
      dialogButtonText: "Написать нам",
      widgetHead: "У вас есть вопросы?",
      widgetText: "Обычное время ответа 5 минут",
      welcomMessage: "Привет.\nКакой у тебя вопрос?",
      widgetAvatarText: "Богдан",
      tabMessageText: "У вас новое сообщение!",
      widgetBeforeOpenText: "Привет) \nВам нужна помощь?",
      messagesBlockHead: "Связь через мессенджеры",
      messagesBlockDescription:
        "Вы можете связаться с нами через удобный мессенджер или отправить сообщение в чате справа здесь!",
      showWidget: true,
    },
    en: {
      widgetHeadStart: "Hello",
      widgetTextStart: "Ready care system for your customers in 30 minutes",
      dialogButtonText: "Message us",
      widgetHead: "Do you have any questions?",
      widgetText: "Typical response time is 5 minutes",
      welcomMessage: "Hello.\nWhat is your question?",
      widgetAvatarText: "Bohdan",
      tabMessageText: "You have a new message!",
      widgetBeforeOpenText: "Hello) \nDo you need help?",
      messagesBlockHead: "Communication via messengers",
      messagesBlockDescription:
        "You can contact us through the convenient messenger or send a message in the chat on the right here!",
      showWidget: true,
    },
    "en-GB": {
      widgetHeadStart: "Hello",
      widgetTextStart: "Ready system of care for your customers in 30 minutes.",
      dialogButtonText: "Write to us",
      widgetHead: "Do you have any questions?",
      widgetText: "Typical response time is 5 minutes",
      welcomMessage: "Hello.\nWhat's your question?",
      widgetAvatarText: "Bogdan",
      tabMessageText: "You have a new message!",
      widgetBeforeOpenText: "Hello, \nDo you need any help?",
      messagesBlockHead: "Communication via messengers",
      messagesBlockDescription:
        "Contact us through a convenient messenger or send a message in the chat on the right here!",
      showWidget: true,
    },
    es: {
      widgetHeadStart: "Hola",
      widgetTextStart:
        "Sistema de atención listo para sus clientes en 30 minutos.",
      dialogButtonText: "Escríbanos",
      widgetHead: "¿Tiene alguna pregunta?",
      widgetText: "El tiempo de respuesta típico es de 5 minutos",
      welcomMessage: "Hola. \n¿cuál es tu pregunta?",
      widgetAvatarText: "Bogdan",
      tabMessageText: "¡Tienes un nuevo mensaje!",
      widgetBeforeOpenText: "¡Hola \nnecesitas ayuda?",
      messagesBlockHead: "Comunicación a través de mensajeros",
      messagesBlockDescription:
        "Contáctanos a través de un mensajero conveniente o envía un mensaje en el chat a la derecha aquí!",
      showWidget: true,
    },
    fr: {
      widgetHeadStart: "Bonjour",
      widgetTextStart: "Système de service prêt pour vos clients en 30 minutes",
      dialogButtonText: "Écrivez-nous",
      widgetHead: "Avez-vous des questions?",
      widgetText: "Le temps de réponse typique est de 5 minutes",
      welcomMessage: "Bonjour.\nQuelle est votre question?",
      widgetAvatarText: "Bogdan",
      tabMessageText: "Vous avez un nouveau message !",
      widgetBeforeOpenText: "Bonjour) \nAvez-vous besoin d'aide ?",
      messagesBlockHead: "Communication par messagers",
      messagesBlockDescription:
        "Contactez-nous via un messager pratique ou envoyez un message dans le chat à droite ici !",
      showWidget: true,
    },
    pl: {
      widgetHeadStart: "Cześć",
      widgetTextStart: "Gotowy system obsługi dla Twoich klientów w 30 minut",
      dialogButtonText: "Napisz do nas",
      widgetHead: "Masz jakieś pytania?",
      widgetText: "Typowy czas odpowiedzi to 5 minut",
      welcomMessage: "Cześć.\nJakie masz pytanie?",
      widgetAvatarText: "Bogdan",
      tabMessageText: "Masz nową wiadomość!",
      widgetBeforeOpenText: "Cześć) \nPotrzebujesz pomocy?",
      messagesBlockHead: "Komunikacja przez komunikatory",
      messagesBlockDescription:
        "Skontaktuj się z nami za pośrednictwem wygodnego messengera lub wyślij wiadomość w czacie po prawej stronie!",
      showWidget: true,
    },
    de: {
      widgetHeadStart: "Hallo",
      widgetTextStart:
        "Bereitstellung eines Kundenbetreuungssystems für Ihre Kunden in 30 Minuten",
      dialogButtonText: "Schreib uns",
      widgetHead: "Hast du Fragen?",
      widgetText: "Die durchschnittliche Antwortzeit beträgt 5 Minuten",
      welcomMessage: "Hallo.\nWas ist deine Frage?",
      widgetAvatarText: "Bogdan",
      tabMessageText: "Sie haben eine neue Nachricht!",
      widgetBeforeOpenText: "Hallo) \nBrauchst du Hilfe?",
      messagesBlockHead: "Kommunikation über Boten",
      messagesBlockDescription:
        "Kontaktieren Sie uns über einen bequemen Messenger oder senden Sie eine Nachricht im Chat rechts hier!",
      showWidget: true,
    },
    nl: {
      widgetHeadStart: "Hallo",
      widgetTextStart: "Een klantensysteem klaar voor uw klanten in 30 minuten",
      dialogButtonText: "Schrijf ons",
      widgetHead: "Heeft u vragen?",
      widgetText: "Gemiddelde antwoordtijd is 5 minuten",
      welcomMessage: "Hallo.\nWat is uw vraag?",
      widgetAvatarText: "Bogdan",
      tabMessageText: "U hebt een nieuw bericht!",
      widgetBeforeOpenText: "Hallo) \nHeeft u hulp nodig?",
      messagesBlockHead: "Communicatie via boodschappers",
      messagesBlockDescription:
        "Neem contact met ons op via een handige messenger of stuur een bericht in de chat rechts hier!",
      showWidget: true,
    },
    pt: {
      widgetHeadStart: "Olá",
      widgetTextStart:
        "Sistema de atendimento ao cliente pronto para seus clientes em 30 minutos",
      dialogButtonText: "Escreva-nos",
      widgetHead: "Você tem alguma pergunta?",
      widgetText: "O tempo médio de resposta é de 5 minutos",
      welcomMessage: "Olá.\nQual é a sua pergunta?",
      widgetAvatarText: "Bogdan",
      tabMessageText: "Você tem uma nova mensagem!",
      widgetBeforeOpenText: "Olá) \nVocê precisa de ajuda?",
      messagesBlockHead: "Comunicação através de mensageiros",
      messagesBlockDescription:
        "Entre em contato conosco através de um mensageiro conveniente ou envie uma mensagem no chat à direita aqui!",
      showWidget: true,
    },
    sv: {
      widgetHeadStart: "Hej",
      widgetTextStart:
        "Vi har ett kundtjänstsystem klart för dina kunder på 30 minuter",
      dialogButtonText: "Kontakta oss",
      widgetHead: "Har du några frågor?",
      widgetText: "Normal svarstid är 5 minuter",
      welcomMessage: "Hej.\nVad är din fråga?",
      widgetAvatarText: "Bogdan",
      tabMessageText: "Du har ett nytt meddelande!",
      widgetBeforeOpenText: "Hej) \nBehöver du hjälp?",
      messagesBlockHead: "Kommunikation genom budbärare",
      messagesBlockDescription:
        "Kontakta oss via en bekväm messenger eller skicka ett meddelande i chatten till höger här!",
      showWidget: true,
    },
    da: {
      widgetHeadStart: "Hej",
      widgetTextStart:
        "Vi har et kundeservicessystem klar til dine kunder inden for 30 minutter",
      dialogButtonText: "Kontakt os",
      widgetHead: "Har du spørgsmål?",
      widgetText: "Normal svartid er 5 minutter",
      welcomMessage: "Hej.\nHvad er dit spørgsmål?",
      widgetAvatarText: "Bogdan",
      tabMessageText: "Du har en ny besked!",
      widgetBeforeOpenText: "Hej) \nHar du brug for hjælp?",
      messagesBlockHead: "Kommunikation gennem budbringere",
      messagesBlockDescription:
        "Du kan kontakte os via en bekvem messenger eller sende en besked i chatten til højre her!",
      showWidget: true,
    },
    no: {
      widgetHeadStart: "Hei",
      widgetTextStart:
        "Vi har et kundeservicesystem klart for dine kunder innen 30 minutter",
      dialogButtonText: "Kontakt oss",
      widgetHead: "Har du spørsmål?",
      widgetText: "Normal svartid er 5 minutter",
      welcomMessage: "Hei.\nHva er spørsmålet ditt?",
      widgetAvatarText: "Bogdan",
      tabMessageText: "Du har en ny melding!",
      widgetBeforeOpenText: "Hei) \nTrenger du hjelp?",
      messagesBlockHead: "Kommunikasjon gjennom budbringere",
      messagesBlockDescription:
        "Du kan kontakte oss via en praktisk messenger eller sende en melding i chatten til høyre her!",
      showWidget: true,
    },
    fi: {
      widgetHeadStart: "Hei",
      widgetTextStart:
        "Valmiina on huolenpitojärjestelmä asiakkaillesi 30 minuutissa",
      dialogButtonText: "Kirjoita meille",
      widgetHead: "Onko sinulla kysyttävää?",
      widgetText: "Vastausaika on yleensä 5 minuuttia",
      welcomMessage: "Hei. \nMikä on kysymyksesi?",
      widgetAvatarText: "Bogdan",
      tabMessageText: "Teillä on uusi viesti!",
      widgetBeforeOpenText: "Hei) \nTarvitsetko apua?",
      messagesBlockHead: "Yhteydenotto messengerin kautta",
      messagesBlockDescription:
        "Voit ottaa meihin yhteyttä kätevän messengerin kautta tai lähettää viestin chatissa täällä oikealla!",
      showWidget: true,
    },
    ja: {
      widgetHeadStart: "こんにちは",
      widgetTextStart:
        "お客様のためのカスタマーケアシステムが30分で準備できます",
      dialogButtonText: "お問い合わせ",
      widgetHead: "何か質問はありますか？",
      widgetText: "通常の返信時間は5分です",
      welcomMessage: "こんにちは。\n何かご質問はありますか？",
      widgetAvatarText: "ボグダン",
      tabMessageText: "新しいメッセージがあります！",
      widgetBeforeOpenText: "こんにちは）\nお手伝いできますか？",
      messagesBlockHead: "メッセンジャーを通じて連絡を取る",
      messagesBlockDescription:
        "便利なメッセンジャーを介してお問い合わせいただくか、こちらの右側のチャットにメッセージを送信することができます！",
      showWidget: true,
    },
    ko: {
      widgetHeadStart: "안녕하세요",
      widgetTextStart:
        "고객님의 고객을 위한 관리 시스템을 30 분 이내에 제공합니다",
      dialogButtonText: "문의하기",
      widgetHead: "문의사항이 있으신가요?",
      widgetText: "일반적인 답변 시간은 5분입니다",
      welcomMessage: "안녕하세요.\n무엇이 궁금하신가요?",
      widgetAvatarText: "보단",
      tabMessageText: "새로운 메시지가 도착했습니다!",
      widgetBeforeOpenText: "안녕하세요. \n도움이 필요하신가요?",
      messagesBlockHead: "메신저를 통한 소통",
      messagesBlockDescription:
        "메신저를 통해 연락하실 수 있습니다. 또는 오른쪽 채팅에서 메시지를 보내실 수도 있습니다!",
      showWidget: true,
    },
  },
  managerPhoto: `${process.env.REACT_APP_BASE_DOMAIN_URL}/assets/img/avatar_placeholder.jpg`,
  managerSecond: `${process.env.REACT_APP_BASE_DOMAIN_URL}/assets/img/manager-second.png`,
  managerThird: `${process.env.REACT_APP_BASE_DOMAIN_URL}/assets/img/manager-third.png`,
  setWidgetBeforeOpenImmage: true,
  isUseInterdomain: false,
  isHideWidgetOnSite: false,
  isOffVolumeWidget: false,
  mainDomain: "",
  languageFormName: "uk",
  locationWidget: "right",
  typeOfWidget: "compact",
  showTelephone: false,
  showEmail: false,
  numberToCall: "",
  adressToWrite: "",
  systemLanguage: {
    ru: false,
    en: true,
    "en-GB": false,
    es: false,
    fr: false,
    pl: false,
    de: false,
    nl: false,
    pt: false,
    sv: false,
    da: false,
    no: false,
    fi: false,
    ja: false,
    ko: false,
    uk: true,
  },
  managerPanelLanguage: "uk",
  widgetTextLanguage: {
    uk: {
      searchManager: "Пошук консультанта...",
      placeHolder: "Напишіть Ваше питання...",
      telephone: "Телефон",
      onlineChat: "Онлайн-чат",
      developmentLabel: "Ми використовуємо ",
      developName: "JediDesk",
      satisfactionLevel: "Чи задоволені ви спілкуванням з менеджером?",
      leaveComment: "Залиште, будь ласка, коментар",
      gratitude: "Дякуємо за відповідь 🥰",
      haveQuestions: "У вас ще залишилися питання?",
      yes: "Так",
      no: "Ні",
      replyFrom: "Ваше повідомлення",
      deletedMessage: "Повідомлення було видалено менеджером",
      editedMessage: "Відредаговано",
      sendMessage: "Надіслати",
      typeComment: "Залишити коментар",
      addManager: "Підключити менеджера",
      addTelephoneAndName: "Вкажіть, будь ласка, ваші контактні дані",
      errorTelephone: "Не правильно введений телефон",
      errorEmail: "Не правильно введена пошта",
      formName: "Ім'я",
    },
    ru: {
      searchManager: "Поиск консультанта...",
      placeHolder: "Напишите ваш вопрос...",
      телефон: "Телефон",
      onlineChat: "Онлайн-чат",
      developmentLabel: "Мы используем ",
      developName: "JediDesk",
      satisfactionLevel: "Удовлетворены ли вы общением с менеджером?",
      leaveComment: "Оставьте, пожалуйста, комментарий",
      gratitude: "Спасибо за ответ 🥰",
      haveQuestions: "У вас еще остались вопросы?",
      yes: "Да",
      no: "Нет",
      replyFrom: "Ваше сообщение",
      deletedMessage: "Сообщение было удалено менеджером",
      editedMessage: "Edited",
      sendMessage: "Отправить",
      typeComment: "Оставить комментарий",
      addManager: "Подключить менеджера",
      addTelephoneAndName: "Укажите пожалуйста ваши контактные данные",
      errorTelephone: "Неправильно введенный телефон",
      errorEmail: "Неправильно введенная почта",
      formName: "Имя",
    },
    en: {
      searchManager: "Searching...",
      placeHolder: "Write your question...",
      telephone: "Telephone",
      onlineChat: "Online сhat",
      developmentLabel: "We use ",
      developName: "JediDesk",
      satisfactionLevel:
        "Are you satisfied with communication with the manager?",
      leaveComment: "Please leave a comment",
      gratitude: "Thank you for your reply 🥰",
      haveQuestions: "Do you still have questions?",
      yes: "Yes",
      no: "No",
      replyFrom: "Your message",
      deletedMessage: "The message has been deleted by a manager",
      editedMessage: "Edited",
      sendMessage: "Send",
      typeComment: "Leave a comment",
      addManager: "Add manager",
      addTelephoneAndName: "Please enter your contact details",
      errorTelephone: "Incorrectly entered telephone number",
      errorEmail: "Incorrectly entered mail",
      formName: "Name",
    },
    "en-GB": {
      searchManager: "Searching...",
      placeHolder: "Write your question...",
      telephone: "Phone",
      onlineChat: "Online сhat",
      developmentLabel: "We use ",
      developName: "JediDesk",
      satisfactionLevel:
        "Are you satisfied with the communication with the manager?",
      leaveComment: "Please leave a comment",
      gratitude: "Thank you for your response 🥰",
      haveQuestions: "Do you have any other questions?",
      yes: "Yes",
      no: "No",
      replyFrom: "Your message",
      deletedMessage: "Message has been deleted by the manager",
      editedMessage: "Edited",
      sendMessage: "Send",
      typeComment: "Leave a comment",
      addManager: "Add manager",
      addTelephoneAndName: "Please enter your contact details",
      errorTelephone: "Incorrectly entered telephone number",
      errorEmail: "Incorrectly entered mail",
      formName: "Name",
    },
    es: {
      searchManager: "Buscando...",
      placeHolder: "Escribe tu pregunta...",
      telephone: "Teléfono",
      onlineChat: "Chat en línea",
      developmentLabel: "Utilizamos ",
      developName: "JediDesk",
      satisfactionLevel: "¿Está satisfecho con la comunicación con el gerente?",
      leaveComment: "Por favor deja un comentario",
      gratitude: "Gracias por tu respuesta 🥰",
      haveQuestions: "¿Tiene alguna otra pregunta?",
      yes: "Sí",
      no: "No",
      replyFrom: "Tu mensaje",
      deletedMessage: "El mensaje ha sido eliminado por el gerente",
      editedMessage: "Editado",
      sendMessage: "Enviar",
      typeComment: "Dejar un comentario",
      addManager: "Agregar administrador",
      addTelephoneAndName: "Por favor introduzca sus datos de contacto",
      errorTelephone: "Número de teléfono ingresado incorrectamente",
      errorEmail: "Incorrectly entered mail",
      formName: "Nombre",
    },
    fr: {
      searchManager: "Recherche...",
      placeHolder: "Écrivez votre question...",
      telephone: "Téléphone",
      onlineChat: "Chat en ligne",
      developmentLabel: "Nous utilisons ",
      developName: "JediDesk",
      satisfactionLevel:
        "Êtes-vous satisfait de la communication avec le gestionnaire ?",
      leaveComment: "S'il vous plaît, laissez un commentaire",
      gratitude: "Merci pour votre réponse 🥰",
      haveQuestions: "Avez-vous d'autres questions ?",
      yes: "Oui",
      no: "Non",
      replyFrom: "Votre message",
      deletedMessage: "Le message a été supprimé par le gestionnaire",
      editedMessage: "Modifié",
      sendMessage: "Envoyer",
      typeComment: "Laisser un commentaire",
      addManager: "Ajouter un gestionnaire",
      addTelephoneAndName: "Please enter your contact details",
      errorTelephone: "Numéro de téléphone mal saisi",
      errorEmail: "Incorrectly entered mail",
      formName: "Nom",
    },
    pl: {
      searchManager: "Wyszukiwanie...",
      placeHolder: "Napisz swoje pytanie...",
      telephone: "Telefon",
      onlineChat: "Czat online",
      developmentLabel: "Korzystamy z ",
      developName: "JediDesk",
      satisfactionLevel: "Czy jesteś zadowolony z komunikacji z menedżerem?",
      leaveComment: "Proszę zostawić komentarz",
      gratitude: "Dziękujemy za odpowiedź 🥰",
      haveQuestions: "Czy masz jakieś inne pytania?",
      yes: "Tak",
      no: "Nie",
      replyFrom: "Twoja wiadomość",
      deletedMessage: "Wiadomość została usunięta przez menedżera",
      editedMessage: "Zmodyfikowano",
      sendMessage: "Wyślij",
      typeComment: "Zostaw komentarz",
      addManager: "Dodaj menedżera",
      addTelephoneAndName: "Please enter your contact details",
      errorTelephone: "Błędnie wpisany numer telefonu",
      errorEmail: "Incorrectly entered mail",
      formName: "Imię",
    },
    de: {
      searchManager: "Suche Berater...",
      placeHolder: "Schreib deine Frage...",
      telephone: "Telefon",
      onlineChat: "Online-Chat",
      developmentLabel: "Wir verwenden ",
      developName: "JediDesk",
      satisfactionLevel:
        "Sind Sie mit der Kommunikation mit dem Manager zufrieden?",
      leaveComment: "Bitte hinterlassen Sie einen Kommentar",
      gratitude: "Vielen Dank für Ihre Antwort 🥰",
      haveQuestions: "Haben Sie weitere Fragen?",
      yes: "Ja",
      no: "Nein",
      replyFrom: "Ihre Nachricht",
      deletedMessage: "Die Nachricht wurde vom Manager gelöscht",
      editedMessage: "Bearbeitet",
      sendMessage: "Senden",
      typeComment: "Hinterlassen Sie einen Kommentar",
      addManager: "Manager hinzufügen",
      addTelephoneAndName: "Please enter your contact details",
      errorTelephone: "Falsch eingegebene Telefonnummer",
      errorEmail: "Incorrectly entered mail",
      formName: "Name",
    },
    nl: {
      searchManager: "Adviseur zoeken...",
      placeHolder: "Schrijf uw vraag...",
      telephone: "Telefoon",
      onlineChat: "Online chat",
      developmentLabel: "Wij gebruiken ",
      developName: "JediDesk",
      satisfactionLevel: "Bent u tevreden met de communicatie met de manager?",
      leaveComment: "Laat alstublieft een opmerking achter",
      gratitude: "Bedankt voor uw antwoord 🥰",
      haveQuestions: "Heeft u nog vragen?",
      yes: "Ja",
      no: "Nee",
      replyFrom: "Uw bericht",
      deletedMessage: "Het bericht is verwijderd door de manager",
      editedMessage: "Bewerkt",
      sendMessage: "Versturen",
      typeComment: "Laat een opmerking achter",
      addManager: "Beheerder toevoegen",
      addTelephoneAndName: "Please enter your contact details",
      errorTelephone: "Telefoonnummer verkeerd ingevoerd",
      errorEmail: "Incorrectly entered mail",
      formName: "Naam",
    },
    pt: {
      searchManager: "Procurando...",
      placeHolder: "Escreva sua pergunta...",
      telephone: "Telefone",
      onlineChat: "Chat online",
      developmentLabel: "Nós usamos ",
      developName: "JediDesk",
      satisfactionLevel:
        "Você está satisfeito com a comunicação com o gerente?",
      leaveComment: "Por favor, deixe um comentário",
      gratitude: "Obrigado pela sua resposta 🥰",
      haveQuestions: "Você tem mais perguntas?",
      yes: "Sim",
      no: "Não",
      replyFrom: "Sua mensagem",
      deletedMessage: "A mensagem foi excluída pelo gerente",
      editedMessage: "Editado",
      sendMessage: "Enviar",
      typeComment: "Deixar um comentário",
      addManager: "Adicionar gerenciador",
      addTelephoneAndName: "Please enter your contact details",
      errorTelephone: "Número de telefone inserido incorretamente",
      errorEmail: "Incorrectly entered mail",
      formName: "Nome",
    },
    sv: {
      searchManager: "Söker konsult...",
      placeHolder: "Skriv din fråga...",
      telephone: "Telefon",
      onlineChat: "Onlinechatt",
      developmentLabel: "Vi använder ",
      developName: "JediDesk",
      satisfactionLevel: "Är du nöjd med kommunikationen med chefen?",
      leaveComment: "Vänligen lämna en kommentar",
      gratitude: "Tack för ditt svar 🥰",
      haveQuestions: "Har du fler frågor?",
      yes: "Ja",
      no: "Nej",
      replyFrom: "Ditt meddelande",
      deletedMessage: "Meddelandet togs bort av chefen",
      editedMessage: "Redigerad",
      sendMessage: "Skicka",
      typeComment: "Lämna en kommentar",
      addManager: "Add Manager",
      addTelephoneAndName: "Please enter your contact details",
      errorTelephone: "Felaktigt inmatat telefonnummer",
      errorEmail: "Incorrectly entered mail",
      formName: "Namn",
    },
    da: {
      searchManager: "Søger konsulent...",
      placeHolder: "Skriv dit spørgsmål...",
      telephone: "Telefon",
      onlineChat: "Online chat",
      developmentLabel: "Vi bruger ",
      developName: "JediDesk",
      satisfactionLevel: "Er du tilfreds med kommunikationen med chefen?",
      leaveComment: "Venligst efterlad en kommentar",
      gratitude: "Tak for dit svar 🥰",
      haveQuestions: "Har du flere spørgsmål?",
      yes: "Ja",
      no: "Nej",
      replyFrom: "Din besked",
      deletedMessage: "Beskeden blev fjernet af chefen",
      editedMessage: "Redigeret",
      sendMessage: "Send",
      typeComment: "Efterlad en kommentar",
      addManager: "Tilføj administrator",
      addTelephoneAndName: "Please enter your contact details",
      errorTelephone: "Forkert indtastet telefonnummer",
      errorEmail: "Incorrectly entered mail",
      formName: "Navn",
    },
    no: {
      searchManager: "Søker konsulent...",
      placeHolder: "Skriv inn spørsmålet ditt...",
      telephone: "Telefon",
      onlineChat: "Online chat",
      developmentLabel: "Vi bruker ",
      developName: "JediDesk",
      satisfactionLevel: "Er du fornøyd med kommunikasjonen med sjefen?",
      leaveComment: "Vennligst legg igjen en kommentar",
      gratitude: "Takk for svaret ditt 🥰",
      haveQuestions: "Har du flere spørsmål?",
      yes: "Ja",
      no: "Nei",
      replyFrom: "Din melding",
      deletedMessage: "Meldingen ble fjernet av sjefen",
      editedMessage: "Redigert",
      sendMessage: "Send",
      typeComment: "Legg igjen en kommentar",
      addManager: "Add Manager",
      addTelephoneAndName: "Please enter your contact details",
      errorTelephone: "Feil oppgitt telefonnummer",
      errorEmail: "Incorrectly entered mail",
      formName: "Navn",
    },
    fi: {
      searchManager: "Konsultin etsiminen...",
      placeHolder: "Kirjoita kysymyksesi...",
      telephone: "Puhelinnumero",
      onlineChat: "Online-keskustelu",
      developmentLabel: "Käytämme evästeitä ",
      developName: "JediDesk",
      satisfactionLevel:
        "Oletteko tyytyväinen johtajan kanssa käymiisi keskusteluihin?",
      leaveComment: "Jätä kommentti, ole hyvä",
      gratitude: "Kiitos vastauksestasi 🥰",
      haveQuestions: "Onko sinulla vielä kysymyksiä?",
      yes: "Kyllä",
      no: "Ei",
      replyFrom: "Viestisi",
      deletedMessage: "Viesti poistettiin johtajan toimesta",
      editedMessage: "Muokattu",
      sendMessage: "Lähetä",
      typeComment: "Jätä kommentti",
      addManager: "Lisää hallinta",
      addTelephoneAndName: "Please enter your contact details",
      errorTelephone: "Väärin syötetty puhelinnumero",
      errorEmail: "Incorrectly entered mail",
      formName: "Nimi",
    },
    ja: {
      searchManager: "検索...",
      placeHolder: "ご質問は何ですか？",
      telephone: "電話",
      onlineChat: "オンラインチャット",
      developmentLabel: "私たちは使っています ",
      developName: "JediDesk",
      satisfactionLevel:
        "マネージャーとのコミュニケーションに満足していますか？",
      leaveComment: "「コメントを残してください",
      gratitude: "回答ありがとうございます 🥰",
      haveQuestions: "まだご質問はありますか？",
      yes: "はい",
      no: "いいえ",
      replyFrom: "あなたのメッセージ",
      deletedMessage: "メッセージがマネージャーによって削除されました",
      editedMessage: "編集されました",
      sendMessage: "送信する",
      typeComment: "コメントを残す",
      addManager: "マネージャーの追加",
      addTelephoneAndName: "Please enter your contact details",
      errorTelephone: "入力された電話番号が間違っています",
      errorEmail: "Incorrectly entered mail",
      formName: "名前",
    },
    ko: {
      searchManager: "컨설턴트 찾는 중...",
      placeHolder: "질문을 입력하세요...",
      telephone: "전화",
      onlineChat: "온라인 채팅",
      developmentLabel: "저희는 사용합니다 ",
      developName: "JediDesk",
      satisfactionLevel: "매니저와의 대화에 만족하셨나요?",
      leaveComment: "의견을 남겨주세요",
      gratitude: "답변해주셔서 감사합니다 🥰",
      haveQuestions: "더 궁금하신 사항이 있으신가요?",
      yes: "네",
      no: "아니요",
      replyFrom: "귀하의 메시지",
      deletedMessage: "메시지가 매니저에 의해 삭제되었습니다",
      editedMessage: "편집됨",
      sendMessage: "보내기",
      typeComment: "의견을 남겨주세요",
      addManager: "관리자 추가",
      addTelephoneAndName: "Please enter your contact details",
      errorTelephone: "잘못 입력된 전화번호",
      errorEmail: "Incorrectly entered mail",
      formName: "이름",
    },
  },
};

export default widgetState;
