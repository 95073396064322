import React from "react";

const InputFileIcon = () => (
  <svg
                      width={26}
                      height={26}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.973 9.75v7.305a2.036 2.036 0 0 0 4.07 0l.007-9.575a3.45 3.45 0 1 0-6.9 0v9.64a4.85 4.85 0 1 0 9.7 0V8.111"
                        stroke="#264166"
                        strokeWidth={1.5}
                        strokeMiterlimit={10}
                        strokeLinecap="round"
                      />
                    </svg>
);

export default InputFileIcon;