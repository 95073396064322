import React from "react";

const SendButton = ({ onClick, color }) => {
  return (
    <div onClick={onClick} className="jedidesk-chat__send-button">
      <svg
        width={24}
        height={18}
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.278 0a.725.725 0 0 0-.569.329.874.874 0 0 0-.12.691L9.213 7.69l7.822 1.309-7.824 1.313-1.624 6.665a.882.882 0 0 0 .067.604.76.76 0 0 0 .431.384.684.684 0 0 0 .554-.052l14.946-8.184a.789.789 0 0 0 .364-.435.886.886 0 0 0 0-.59.79.79 0 0 0-.364-.433L8.64.085A.688.688 0 0 0 8.278 0ZM1.835 2.656a.733.733 0 0 0-.643.407.88.88 0 0 0 0 .813.733.733 0 0 0 .643.406H5.88c.265 0 .51-.154.643-.406a.88.88 0 0 0 0-.813.733.733 0 0 0-.643-.407H1.835ZM.69 8.186a.744.744 0 0 0-.61.444.881.881 0 0 0 .035.802c.14.241.385.385.647.378h6.602c.2.005.392-.079.535-.232a.852.852 0 0 0 .223-.58.852.852 0 0 0-.223-.581.71.71 0 0 0-.535-.232H.762a.772.772 0 0 0-.071 0Zm1.145 5.522a.709.709 0 0 0-.535.231.853.853 0 0 0-.224.581c0 .219.08.428.224.581a.708.708 0 0 0 .535.231H5.88c.2.005.392-.078.535-.23a.852.852 0 0 0 .223-.582.853.853 0 0 0-.223-.58.709.709 0 0 0-.535-.232H1.835Z"
        />
      </svg>
    </div>
  );
};

export default SendButton;
